<template>
    <div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>营地管理</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span class="title_class">SPBCN营地管理</span>
                </el-col>
            </el-row>
            <el-row :gutter="30">
                <el-col
                    style="margin-top: 30px"
                    :span="6"
                    v-for="(item, i) of racingToolList"
                    v-bind:key="i"
                >
                    <gameToolBackView
                        :itemData="item"
                        :key="i"
                        @itemClicked="racingToolClicked"
                    ></gameToolBackView>
                </el-col>
            </el-row>
        </el-card>
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span class="title_class">营地管理</span>
                </el-col>
            </el-row>
            <el-row :gutter="30">
                <el-col
                    style="margin-top: 30px"
                    :span="6"
                    v-for="(item, i) of nasccList"
                    v-bind:key="i"
                >
                    <gameToolBackView
                        :itemData="item"
                        :key="i"
                        @itemClicked="racingToolClicked"
                    ></gameToolBackView>
                </el-col>
            </el-row>
        </el-card>
    </div>
</template>

<script>
export default {
  data () {
    return {
      racingToolList: [
        {
          rightTitle: '营地信息管理',
          rightDesc: '查看营地信息',
          icon: 'icon-cuoqingdengji',
          menuTag: '1',
          flag: this.$chnEngStatusCode.camp_list_data,
          turnUrl: '/raceManagement/camp/campListData'
        },
        {
          rightTitle: '报名信息统计',
          rightDesc: '查看报名数据',
          icon: 'icon-shijuan',
          menuTag: '2',
          flag: this.$chnEngStatusCode.camp_signuplist,
          turnUrl: '/raceManagement/camp/campSignupList'
        },
        {
          rightTitle: '咨询信息',
          rightDesc: '可查看咨询的信息',
          icon: 'icon-baoming',
          menuTag: '3',
          flag: this.$chnEngStatusCode.camp_lnquiriesList,
          turnUrl: '/raceManagement/camp/campLnquiriesList'
        }
      ],
      nasccList: [
        {
          rightTitle: 'NASCC北美赛营地',
          rightDesc: '报名审核、合同管理、美签申请',
          icon: 'icon-N',
          menuTag: '4',
          flag: this.$chnEngStatusCode.camp_signuplist,
          turnUrl: '/raceManagement/camp/nasccSignupList'
        }, {
          rightTitle: 'GCSCC全球顶级精英拼词夏训营',
          rightDesc: '报名管理',
          icon: 'icon-G',
          menuTag: '5',
          flag: this.$chnEngStatusCode.camp_signuplist,
          turnUrl: '/raceManagement/camp/gcscc'
        }
      ]
    }
  },
  methods: {
    // 赛务工具点击回调
    racingToolClicked (itemData) {
      switch (itemData.flag) {
        case this.$chnEngStatusCode.camp_list_data: case this.$chnEngStatusCode.camp_signuplist: case this.$chnEngStatusCode.camp_lnquiriesList: case this.$chnEngStatusCode.nascc_signuplist:
          this.$router.push(itemData.turnUrl)
          break
        default:
          break
      }
    }
  }
}
</script>

<style>
</style>
